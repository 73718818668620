import React, { useEffect, useRef, useState } from 'react';
import FamilyTree from "@balkangraph/familytree.js";
import '../styles/Tree.css';
import { toBeEnabled } from '@testing-library/jest-dom/dist/matchers';

function Tree() {
  const treeRef = useRef(null);
  
  FamilyTree.templates.john = Object.assign({}, FamilyTree.templates.base);
  FamilyTree.templates.john.defs = `<style>
                                      .{randId} .bft-edit-form-header, .{randId} .bft-img-button{
                                          background-color: #aeaeae;
                                      }
                                      .{randId}.male .bft-edit-form-header, .{randId}.male .bft-img-button{
                                          background-color: #039BE5;
                                      }        
                                      .{randId}.male div.bft-img-button:hover{
                                          background-color: #F57C00;
                                      }
                                      .{randId}.female .bft-edit-form-header, .{randId}.female .bft-img-button{
                                          background-color: #F57C00;
                                      }        
                                      .{randId}.female div.bft-img-button:hover{
                                          background-color: #039BE5;
                                      }
                                  </style>
                                  <clipPath id="john_img_0"><rect x="6" y="6" rx="54" ry="54" width="108" height="108"></rect></clipPath>
                                  ${FamilyTree.gradientCircleForDefs('circle', '#aeaeae', 60, 5)}
                                  ${FamilyTree.gradientCircleForDefs('male_circle', '#039BE5', 60, 5)}
                                  ${FamilyTree.gradientCircleForDefs('female_circle', '#F57C00', 60, 5)}`;
  FamilyTree.templates.john.field_0 = 
      '<text ' + FamilyTree.attr.width + ' ="230" style="font-size: 14px;font-weight:bold;" fill="#aeaeae" x="60" y="143" text-anchor="middle">{val}</text>';
  FamilyTree.templates.john.field_1 = 
      '<text ' + FamilyTree.attr.width + ' ="150" style="font-size: 13px;" fill="#aeaeae" x="60" y="150" text-anchor="middle">{val}</text>';
  FamilyTree.templates.john.node = '<use x="0" y="0" xlink:href="#circle" />';
  FamilyTree.templates.john.img_0 = 
      '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#john_img_0)" xlink:href="{val}" x="6" y="6" width="108" height="108"></image>';
  FamilyTree.templates.john.ripple = {
      radius: 60,
      color: "#e6e6e6",
      rect: null
  };
  

  FamilyTree.templates.john.size = [120, 120]
  FamilyTree.templates.john_male = Object.assign({}, FamilyTree.templates.john);
  FamilyTree.templates.john_male.node += '<use x="0" y="0" xlink:href="#male_circle" />';
  FamilyTree.templates.john_male.ripple = {
      radius: 60,
      color: "#039BE5",
      rect: null
  };
  FamilyTree.templates.john_female = Object.assign({}, FamilyTree.templates.john);
  FamilyTree.templates.john_female.node += '<use x="0" y="0" xlink:href="#female_circle" />';
  FamilyTree.templates.john_female.ripple = {
      radius: 60,
      color: "#F57C00",
      rect: null
  };

  FamilyTree.SEARCH_PLACEHOLDER = "Leita eftir nafni...";
  FamilyTree.templates.tommy_male.plus =
    '<circle cx="0" cy="0" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
    + '<line x1="-11" y1="0" x2="11" y2="0" stroke-width="1" stroke="#aeaeae"></line>'
    + '<line x1="0" y1="-11" x2="0" y2="11" stroke-width="1" stroke="#aeaeae"></line>';
  FamilyTree.templates.tommy_male.minus =
      '<circle cx="0" cy="0" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
      + '<line x1="-11" y1="0" x2="11" y2="0" stroke-width="1" stroke="#aeaeae"></line>';
  FamilyTree.templates.tommy_female.plus =
      '<circle cx="0" cy="0" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
      + '<line x1="-11" y1="0" x2="11" y2="0" stroke-width="1" stroke="#aeaeae"></line>'
      + '<line x1="0" y1="-11" x2="0" y2="11" stroke-width="1" stroke="#aeaeae"></line>';
  FamilyTree.templates.tommy_female.minus =
      '<circle cx="0" cy="0" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
      + '<line x1="-11" y1="0" x2="11" y2="0" stroke-width="1" stroke="#aeaeae"></line>';

  FamilyTree.templates.tommy_female.defs = '<g transform="matrix(0.05,0,0,0.05,-12,-9)" id="heart"><path fill="#F57C00" d="M438.482,58.61c-24.7-26.549-59.311-41.655-95.573-41.711c-36.291,0.042-70.938,15.14-95.676,41.694l-8.431,8.909  l-8.431-8.909C181.284,5.762,98.663,2.728,45.832,51.815c-2.341,2.176-4.602,4.436-6.778,6.778 c-52.072,56.166-52.072,142.968,0,199.134l187.358,197.581c6.482,6.843,17.284,7.136,24.127,0.654 c0.224-0.212,0.442-0.43,0.654-0.654l187.29-197.581C490.551,201.567,490.551,114.77,438.482,58.61z"/><g>';
  FamilyTree.templates.tommy_male.defs = '<g transform="matrix(0.05,0,0,0.05,-12,-9)" id="heart"><path fill="#F57C00" d="M438.482,58.61c-24.7-26.549-59.311-41.655-95.573-41.711c-36.291,0.042-70.938,15.14-95.676,41.694l-8.431,8.909  l-8.431-8.909C181.284,5.762,98.663,2.728,45.832,51.815c-2.341,2.176-4.602,4.436-6.778,6.778 c-52.072,56.166-52.072,142.968,0,199.134l187.358,197.581c6.482,6.843,17.284,7.136,24.127,0.654 c0.224-0.212,0.442-0.43,0.654-0.654l187.29-197.581C490.551,201.567,490.551,114.77,438.482,58.61z"/><g>';



  useEffect(() => {
    if (treeRef.current) {
      new FamilyTree(treeRef.current, {
        enableSearch: false,
        nodeMouseClick: 'none',
        searchFields: ['name'],
        siblingSeparation: 50,
        scaleInitial: FamilyTree.match.height,
        levelSeparation: 50,
        minPartnerSeparation: 50,
        miniMap:false,
        template: 'hugo',
        click: {
            node: () => true, // Return false to disable clicking on the nodes
          },
        toolbar: {
            layout: true,
            zoom: true,
            fit: true,
            expandAll: false,
            fullScreen: true
        },
        nodeBinding: {
          field_0: 'name',
          img_0: 'img',
          field_1: 'birth',
        },
        zoom: {
            speed: 35,
            smooth: 15
        },
        nodes:[
          {
            id: "1",
            name: "Helgi Hannesson Haraldsson",
            pids: [
              "2"
            ],
            mid: "",
            fid: "",
            gender: "male",
            birth: "23-02-1915",
            death: "36035",
            img: ""
          },
          {
            id: "2",
            name: "Marselína Kjartansdóttir",
            pids: [
              "1"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "07-07-1925",
            death: "32842",
            img: ""
          },
          {
            id: "3",
            name: "Rósa Dóra Helgadóttir",
            pids: [
              "10"
            ],
            mid: "2",
            fid: "1",
            gender: "female",
            birth: "16-12-1940",
            death: "36278",
            img: ""
          },
          {
            id: "4",
            name: "Hrafnhildur Helgadóttir",
            pids: [
              ""
            ],
            mid: "2",
            fid: "1",
            gender: "female",
            birth: "10-01-1948",
            death: "",
            img: ""
          },
          {
            id: "5",
            name: "Helga Halldóra Helgadóttir",
            pids: [
              "11"
            ],
            mid: "2",
            fid: "1",
            gender: "female",
            birth: "22-09-1949",
            death: "",
            img: ""
          },
          {
            id: "6",
            name: "stúlka Helgadóttir",
            pids: [
              ""
            ],
            mid: "2",
            fid: "1",
            gender: "female",
            birth: "01-10-1951",
            death: "18973",
            img: ""
          },
          {
            id: "7",
            name: "Kjartan Helgason",
            pids: [
              "12"
            ],
            mid: "2",
            fid: "1",
            gender: "male",
            birth: "03-11-1952",
            death: "",
            img: ""
          },
          {
            id: "8",
            name: "Haraldur Sveinbjörn Helgason",
            pids: [
              "13"
            ],
            mid: "2",
            fid: "1",
            gender: "male",
            birth: "27-12-1957",
            death: "",
            img: ""
          },
          {
            id: "9",
            name: "Sólrún Helgadóttir",
            pids: [
              ""
            ],
            mid: "2",
            fid: "1",
            gender: "female",
            birth: "23-05-1961",
            death: "44154",
            img: ""
          },
          {
            id: "10",
            name: "Pétur Jósefsson",
            pids: [
              "3"
            ],
            mid: "",
            fid: "",
            gender: "male",
            birth: "13-07-1937",
            death: "44223",
            img: ""
          },
          {
            id: "11",
            name: "Reynir Adolfsson",
            pids: [
              "5"
            ],
            mid: "",
            fid: "",
            gender: "male",
            birth: "25-06-1948",
            death: "",
            img: ""
          },
          {
            id: "12",
            name: "Elín Margrét Hallgrímsdóttir",
            pids: [
              "7"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "17-10-1952",
            death: "",
            img: ""
          },
          {
            id: "13",
            name: "Hulda Stefánsdóttir",
            pids: [
              "8"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "31-12-1958",
            death: "",
            img: ""
          },
          {
            id: "14",
            name: "Helgi Pétursson",
            pids: [
              "35"
            ],
            mid: "3",
            fid: "10",
            gender: "male",
            birth: "24-09-1959",
            death: "",
            img: ""
          },
          {
            id: "15",
            name: "Halldór Pétursson",
            pids: [
              "36"
            ],
            mid: "3",
            fid: "10",
            gender: "male",
            birth: "17-12-1960",
            death: "",
            img: ""
          },
          {
            id: "16",
            name: "Hildur Dóru Pétursdóttir",
            pids: [
              "37"
            ],
            mid: "3",
            fid: "10",
            gender: "female",
            birth: "16-02-1963",
            death: "",
            img: ""
          },
          {
            id: "17",
            name: "Hólmfríður Pétursdóttir",
            pids: [
              "38"
            ],
            mid: "3",
            fid: "10",
            gender: "female",
            birth: "11-05-1964",
            death: "",
            img: ""
          },
          {
            id: "18",
            name: "Þorkell Máni Pétursson",
            pids: [
              "39"
            ],
            mid: "3",
            fid: "10",
            gender: "male",
            birth: "01-03-1974",
            death: "",
            img: ""
          },
          {
            id: "19",
            name: "Arnkell Logi Pétursson",
            pids: [
              "40"
            ],
            mid: "3",
            fid: "10",
            gender: "male",
            birth: "01-03-1974",
            death: "",
            img: ""
          },
          {
            id: "20",
            name: "Kjartan Örn Kjartansson",
            pids: [
              "41"
            ],
            mid: "4",
            fid: "",
            gender: "male",
            birth: "16-04-1967",
            death: "",
            img: ""
          },
          {
            id: "21",
            name: "Rósa Mjöll Heimisdóttir",
            pids: [
              "42"
            ],
            mid: "4",
            fid: "",
            gender: "female",
            birth: "01-06-1972",
            death: "",
            img: ""
          },
          {
            id: "22",
            name: "Lína Dögg Halldórsdóttir",
            pids: [
              "43"
            ],
            mid: "4",
            fid: "",
            gender: "female",
            birth: "25-01-1976",
            death: "",
            img: ""
          },
          {
            id: "23",
            name: "Halla Björk Reynisdóttir",
            pids: [
              "44"
            ],
            mid: "5",
            fid: "11",
            gender: "female",
            birth: "17-09-1967",
            death: "",
            img: ""
          },
          {
            id: "24",
            name: "Hrafnkell Reynisson",
            pids: [
              "45"
            ],
            mid: "5",
            fid: "11",
            gender: "male",
            birth: "22-06-1969",
            death: "",
            img: ""
          },
          {
            id: "25",
            name: "Ragnhildur Reynisdóttir",
            pids: [
              ""
            ],
            mid: "5",
            fid: "11",
            gender: "female",
            birth: "21-11-1971",
            death: "",
            img: ""
          },
          {
            id: "26",
            name: "Gauti Þór Reynisson",
            pids: [
              "46"
            ],
            mid: "5",
            fid: "11",
            gender: "male",
            birth: "08-02-1976",
            death: "",
            img: ""
          },
          {
            id: "27",
            name: "Jón Helgi Elínar Kjartansson",
            pids: [
              "47"
            ],
            mid: "12",
            fid: "7",
            gender: "male",
            birth: "12-04-1990",
            death: "",
            img: ""
          },
          {
            id: "28",
            name: "Freydís Björk Kjartansdóttir",
            pids: [
              ""
            ],
            mid: "12",
            fid: "7",
            gender: "female",
            birth: "04-10-1994",
            death: "",
            img: ""
          },
          {
            id: "29",
            name: "Anna Haraldsdóttir",
            pids: [
              "48"
            ],
            mid: "13",
            fid: "8",
            gender: "female",
            birth: "20-10-1979",
            death: "",
            img: ""
          },
          {
            id: "30",
            name: "Helgi Haraldsson",
            pids: [
              "49"
            ],
            mid: "13",
            fid: "8",
            gender: "male",
            birth: "18-08-1990",
            death: "",
            img: ""
          },
          {
            id: "31",
            name: "Tómas Helgi Jónsson",
            pids: [
              ""
            ],
            mid: "9",
            fid: "",
            gender: "male",
            birth: "21-08-1978",
            death: "",
            img: ""
          },
          {
            id: "32",
            name: "Jón Birkir Jónsson",
            pids: [
              ""
            ],
            mid: "9",
            fid: "",
            gender: "male",
            birth: "18-10-1987",
            death: "",
            img: ""
          },
          {
            id: "33",
            name: "Andri Már Jónsson",
            pids: [
              "50"
            ],
            mid: "9",
            fid: "",
            gender: "male",
            birth: "24-06-1992",
            death: "",
            img: ""
          },
          {
            id: "34",
            name: "Alda Ósk Jónsdóttir",
            pids: [
              ""
            ],
            mid: "9",
            fid: "",
            gender: "female",
            birth: "27-04-1994",
            death: "",
            img: ""
          },
          {
            id: "35",
            name: "Lísa María Pétursson",
            pids: [
              "14"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "10-08-1960",
            death: "",
            img: ""
          },
          {
            id: "36",
            name: "Halldóra Ingibergsdóttir",
            pids: [
              "15"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "25-02-1966",
            death: "",
            img: ""
          },
          {
            id: "37",
            name: "Óliver John Kentish",
            pids: [
              "16"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "25-06-1954",
            death: "",
            img: ""
          },
          {
            id: "38",
            name: "Arnar Helgi Kristjánsson",
            pids: [
              "17"
            ],
            mid: "",
            fid: "",
            gender: "male",
            birth: "30-05-1964",
            death: "",
            img: ""
          },
          {
            id: "39",
            name: "Dröfn Guðjónsdóttir",
            pids: [
              "18"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "10-11-1981",
            death: "",
            img: ""
          },
          {
            id: "40",
            name: "Marta María Hafsteinsdóttir",
            pids: [
              "19"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "17-02-1983",
            death: "",
            img: ""
          },
          {
            id: "41",
            name: "Guðbjörg Kristín Bárðardóttir",
            pids: [
              "20"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "08-08-1972",
            death: "",
            img: ""
          },
          {
            id: "42",
            name: "Þórleifur Stefán Björnsson",
            pids: [
              "21"
            ],
            mid: "",
            fid: "",
            gender: "male",
            birth: "03-10-1970",
            death: "",
            img: ""
          },
          {
            id: "43",
            name: "Haukur Freyr Reynisson",
            pids: [
              "22"
            ],
            mid: "",
            fid: "",
            gender: "male",
            birth: "20-03-1971",
            death: "",
            img: ""
          },
          {
            id: "44",
            name: "Preben Jón Pétursson",
            pids: [
              "23"
            ],
            mid: "",
            fid: "",
            gender: "male",
            birth: "29-07-1966",
            death: "",
            img: ""
          },
          {
            id: "45",
            name: "Lilja Kolbrún Bjarnadóttir",
            pids: [
              "24"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "24-07-1969",
            death: "",
            img: ""
          },
          {
            id: "46",
            name: "Annemieke Barbara Presburg",
            pids: [
              "26"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "14-02-1972",
            death: "",
            img: ""
          },
          {
            id: "47",
            name: "Hrefna Rún Magnúsdóttir",
            pids: [
              "27"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "04-12-1991",
            death: "",
            img: ""
          },
          {
            id: "48",
            name: "Arnaldur Kári Valdemarsson",
            pids: [
              "29"
            ],
            mid: "",
            fid: "",
            gender: "male",
            birth: "20-12-1979",
            death: "",
            img: ""
          },
          {
            id: "49",
            name: "Lína Björk Stefánsdóttir",
            pids: [
              "30"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "18-07-1991",
            death: "",
            img: ""
          },
          {
            id: "50",
            name: "Helena Björnsdóttir",
            pids: [
              "33"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "16-03-1988",
            death: "",
            img: ""
          },
          {
            id: "51",
            name: "Linda Dóra Helgadóttir",
            pids: [
              "100"
            ],
            mid: "35",
            fid: "14",
            gender: "female",
            birth: "28-06-1986",
            death: "",
            img: ""
          },
          {
            id: "52",
            name: "Kristófer Róbert Helgason",
            pids: [
              ""
            ],
            mid: "35",
            fid: "14",
            gender: "male",
            birth: "02-06-1987",
            death: "43039",
            img: ""
          },
          {
            id: "53",
            name: "Höskuldur Pétur Halldórsson",
            pids: [
              ""
            ],
            mid: "",
            fid: "15",
            gender: "male",
            birth: "25-02-1985",
            death: "",
            img: ""
          },
          {
            id: "54",
            name: "Hólmfríður Rósa Halldórsdóttir",
            pids: [
              ""
            ],
            mid: "",
            fid: "15",
            gender: "female",
            birth: "04-03-1991",
            death: "",
            img: ""
          },
          {
            id: "55",
            name: "Edda Þöll Kentish",
            pids: [
              "101"
            ],
            mid: "16",
            fid: "37",
            gender: "female",
            birth: "21-08-1984",
            death: "",
            img: ""
          },
          {
            id: "56",
            name: "Pálmi Hrafn Tryggvason",
            pids: [
              "102"
            ],
            mid: "17",
            fid: "",
            gender: "male",
            birth: "01-11-1985",
            death: "",
            img: ""
          },
          {
            id: "57",
            name: "Pétur Orri Tryggvason",
            pids: [
              "103"
            ],
            mid: "17",
            fid: "",
            gender: "male",
            birth: "11-10-1988",
            death: "",
            img: ""
          },
          {
            id: "58",
            name: "Sunna Margrét Tryggvadóttir",
            pids: [
              "104"
            ],
            mid: "17",
            fid: "",
            gender: "female",
            birth: "07-01-1997",
            death: "",
            img: ""
          },
          {
            id: "59",
            name: "Ása Arnarsdóttir",
            pids: [
              ""
            ],
            mid: "17",
            fid: "38",
            gender: "female",
            birth: "22-05-2003",
            death: "",
            img: ""
          },
          {
            id: "60",
            name: "Hildur Ásta Þorkelsdóttir",
            pids: [
              ""
            ],
            mid: "39",
            fid: "18",
            gender: "female",
            birth: "30-08-2005",
            death: "",
            img: ""
          },
          {
            id: "61",
            name: "Sigrún Heba Þorkelsdóttir",
            pids: [
              ""
            ],
            mid: "39",
            fid: "18",
            gender: "female",
            birth: "17-07-2007",
            death: "",
            img: ""
          },
          {
            id: "62",
            name: "Hrafnhildur Dóra Þorkelsdóttir",
            pids: [
              ""
            ],
            mid: "39",
            fid: "18",
            gender: "female",
            birth: "16-08-2013",
            death: "",
            img: ""
          },
          {
            id: "63",
            name: "Sigrún Dóra Arnkelsdóttir",
            pids: [
              ""
            ],
            mid: "49",
            fid: "19",
            gender: "female",
            birth: "11-08-2013",
            death: "",
            img: ""
          },
          {
            id: "64",
            name: "Dagur Steinn Arnkelsson",
            pids: [
              ""
            ],
            mid: "40",
            fid: "19",
            gender: "male",
            birth: "26-02-2016",
            death: "",
            img: ""
          },
          {
            id: "65",
            name: "Kolfinna Hildur Kjartansdóttir",
            pids: [
              ""
            ],
            mid: "41",
            fid: "20",
            gender: "female",
            birth: "07-04-1991",
            death: "",
            img: ""
          },
          {
            id: "66",
            name: "Hrafn Helgi Kjartansson",
            pids: [
              "105"
            ],
            mid: "41",
            fid: "20",
            gender: "male",
            birth: "09-06-1994",
            death: "",
            img: ""
          },
          {
            id: "67",
            name: "Úlfur Gunnar Kjartansson",
            pids: [
              ""
            ],
            mid: "41",
            fid: "20",
            gender: "male",
            birth: "30-01-1998",
            death: "",
            img: ""
          },
          {
            id: "68",
            name: "Álfhildur Rósa Kjartansdóttir",
            pids: [
              ""
            ],
            mid: "41",
            fid: "20",
            gender: "female",
            birth: "12-12-2000",
            death: "",
            img: ""
          },
          {
            id: "69",
            name: "Bárður Örn Kjartansson",
            pids: [
              ""
            ],
            mid: "41",
            fid: "20",
            gender: "male",
            birth: "18-01-2004",
            death: "",
            img: ""
          },
          {
            id: "70",
            name: "Örn Kolur Kjartansson",
            pids: [
              ""
            ],
            mid: "41",
            fid: "20",
            gender: "male",
            birth: "10-03-2008",
            death: "",
            img: ""
          },
          {
            id: "71",
            name: "Aron Örn Þórleifsson",
            pids: [
              "106"
            ],
            mid: "21",
            fid: "42",
            gender: "male",
            birth: "09-08-1992",
            death: "",
            img: ""
          },
          {
            id: "72",
            name: "Katla Þöll Þórleifsdóttir",
            pids: [
              ""
            ],
            mid: "21",
            fid: "42",
            gender: "female",
            birth: "11-12-1998",
            death: "",
            img: ""
          },
          {
            id: "73",
            name: "Þórey Edda Þórleifsdóttir",
            pids: [
              ""
            ],
            mid: "21",
            fid: "42",
            gender: "female",
            birth: "23-08-2000",
            death: "",
            img: ""
          },
          {
            id: "74",
            name: "Björn Orri Þórleifsson",
            pids: [
              ""
            ],
            mid: "21",
            fid: "42",
            gender: "male",
            birth: "07-06-2005",
            death: "",
            img: ""
          },
          {
            id: "75",
            name: "Reynir Freyr Hauksson",
            pids: [
              "107"
            ],
            mid: "22",
            fid: "43",
            gender: "male",
            birth: "23-11-1999",
            death: "",
            img: ""
          },
          {
            id: "76",
            name: "Kormákur Helgi Hauksson",
            pids: [
              ""
            ],
            mid: "22",
            fid: "43",
            gender: "male",
            birth: "22-01-2003",
            death: "",
            img: ""
          },
          {
            id: "77",
            name: "Hrafnhildur Karen Hauksdóttir",
            pids: [
              ""
            ],
            mid: "22",
            fid: "43",
            gender: "female",
            birth: "01-08-2004",
            death: "",
            img: ""
          },
          {
            id: "78",
            name: "Margrét Ýr Prebensdóttir",
            pids: [
              "108"
            ],
            mid: "23",
            fid: "44",
            gender: "female",
            birth: "09-10-1990",
            death: "",
            img: ""
          },
          {
            id: "79",
            name: "Karína Reyn Prebens Hölludóttir",
            pids: [
              ""
            ],
            mid: "23",
            fid: "44",
            gender: "female",
            birth: "01-12-1994",
            death: "",
            img: ""
          },
          {
            id: "80",
            name: "Halldóra Kolka Prebensdóttir",
            pids: [
              ""
            ],
            mid: "23",
            fid: "44",
            gender: "female",
            birth: "11-02-2002",
            death: "",
            img: ""
          },
          {
            id: "81",
            name: "Hrafn Orri Hrafnkelsson",
            pids: [
              "109"
            ],
            mid: "",
            fid: "24",
            gender: "male",
            birth: "12-07-1994",
            death: "",
            img: ""
          },
          {
            id: "82",
            name: "Katrín Ynja Hrafnkelsdóttir",
            pids: [
              ""
            ],
            mid: "45",
            fid: "24",
            gender: "female",
            birth: "12-04-2000",
            death: "",
            img: ""
          },
          {
            id: "83",
            name: "Bjarni Hrafnkelsson",
            pids: [
              ""
            ],
            mid: "45",
            fid: "24",
            gender: "male",
            birth: "27-01-2004",
            death: "",
            img: ""
          },
          {
            id: "84",
            name: "Hilmir Hrafnkelsson",
            pids: [
              ""
            ],
            mid: "45",
            fid: "24",
            gender: "male",
            birth: "07-05-2007",
            death: "",
            img: ""
          },
          {
            id: "85",
            name: "Kristinn Þorri Þrastarson",
            pids: [
              "110"
            ],
            mid: "25",
            fid: "",
            gender: "male",
            birth: "30-09-1989",
            death: "",
            img: ""
          },
          {
            id: "86",
            name: "Birta Káradóttir",
            pids: [
              ""
            ],
            mid: "25",
            fid: "",
            gender: "female",
            birth: "07-06-1999",
            death: "",
            img: ""
          },
          {
            id: "87",
            name: "Sunna Káradóttir",
            pids: [
              ""
            ],
            mid: "25",
            fid: "",
            gender: "female",
            birth: "20-08-2001",
            death: "",
            img: ""
          },
          {
            id: "88",
            name: "Ýr Aimée Gautadóttir Presburg",
            pids: [
              ""
            ],
            mid: "46",
            fid: "26",
            gender: "female",
            birth: "11-04-2000",
            death: "",
            img: ""
          },
          {
            id: "89",
            name: "Nói Gautason Presburg",
            pids: [
              ""
            ],
            mid: "46",
            fid: "26",
            gender: "male",
            birth: "26-06-2002",
            death: "",
            img: ""
          },
          {
            id: "90",
            name: "Máni Gautason Presburg",
            pids: [
              ""
            ],
            mid: "46",
            fid: "26",
            gender: "male",
            birth: "17-01-2006",
            death: "",
            img: ""
          },
          {
            id: "91",
            name: "Nanna Líf Gautadóttir Presburg",
            pids: [
              ""
            ],
            mid: "46",
            fid: "26",
            gender: "female",
            birth: "29-04-2008",
            death: "",
            img: ""
          },
          {
            id: "92",
            name: "Haraldur Orri Arnarsson",
            pids: [
              ""
            ],
            mid: "29",
            fid: "",
            gender: "male",
            birth: "26-05-2001",
            death: "",
            img: ""
          },
          {
            id: "93",
            name: "Emma Karen Anna Helgadóttir",
            pids: [
              ""
            ],
            mid: "49",
            fid: "30",
            gender: "female",
            birth: "13-04-2009",
            death: "",
            img: ""
          },
          {
            id: "94",
            name: "Birgir Hrannar Helgason",
            pids: [
              ""
            ],
            mid: "49",
            fid: "30",
            gender: "male",
            birth: "15-02-2011",
            death: "",
            img: ""
          },
          {
            id: "95",
            name: "Stefán Darri Helgason",
            pids: [
              ""
            ],
            mid: "49",
            fid: "30",
            gender: "male",
            birth: "10-11-2015",
            death: "",
            img: ""
          },
          {
            id: "96",
            name: "Kristján Örn Tómasson",
            pids: [
              ""
            ],
            mid: "",
            fid: "31",
            gender: "male",
            birth: "04-03-2003",
            death: "",
            img: ""
          },
          {
            id: "97",
            name: "Alexandra Ósk Andradóttir",
            pids: [
              ""
            ],
            mid: "50",
            fid: "33",
            gender: "female",
            birth: "03-05-2021",
            death: "",
            img: ""
          },
          {
            id: "98",
            name: "Sólrún Erla Óskarsdóttir",
            pids: [
              ""
            ],
            mid: "34",
            fid: "",
            gender: "female",
            birth: "02-01-2014",
            death: "",
            img: ""
          },
          {
            id: "99",
            name: "Valgarður Andri Óskarsson",
            pids: [
              ""
            ],
            mid: "34",
            fid: "",
            gender: "male",
            birth: "06-01-2015",
            death: "",
            img: ""
          },
          {
            id: "100",
            name: "Samuel Jonathan Brown",
            pids: [
              "51"
            ],
            mid: "",
            fid: "",
            gender: "male",
            birth: "12-01-1986",
            death: "",
            img: ""
          },
          {
            id: "101",
            name: "Gunnar Benediktsson",
            pids: [
              "55"
            ],
            mid: "",
            fid: "",
            gender: "male",
            birth: "10-11-1976",
            death: "",
            img: ""
          },
          {
            id: "102",
            name: "María Hólmgrímsdóttir",
            pids: [
              "56"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "11-11-1990",
            death: "",
            img: ""
          },
          {
            id: "103",
            name: "Erla Guðmundsdóttir",
            pids: [
              "57"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "13-01-1992",
            death: "",
            img: ""
          },
          {
            id: "104",
            name: "Jarl Magnus Riiber",
            pids: [
              "58"
            ],
            mid: "",
            fid: "",
            gender: "male",
            birth: "15-10-1997",
            death: "",
            img: ""
          },
          {
            id: "105",
            name: "Anna María Ingadóttir",
            pids: [
              "66"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "23-05-1998",
            death: "",
            img: ""
          },
          {
            id: "106",
            name: "Brynja Reynisdóttir",
            pids: [
              "71"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "01-09-1994",
            death: "",
            img: ""
          },
          {
            id: "107",
            name: "Margrét Sól Jónasdóttir",
            pids: [
              "75"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "05-02-1995",
            death: "",
            img: ""
          },
          {
            id: "108",
            name: "Snorri Sigurbergsson",
            pids: [
              "78"
            ],
            mid: "",
            fid: "",
            gender: "male",
            birth: "15-08-1992",
            death: "",
            img: ""
          },
          {
            id: "109",
            name: "Bára Dröfn Árnadóttir",
            pids: [
              "81"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "16-10-1994",
            death: "",
            img: ""
          },
          {
            id: "110",
            name: "Iðunn Lúðvíksdóttir",
            pids: [
              "85"
            ],
            mid: "",
            fid: "",
            gender: "female",
            birth: "04-07-1991",
            death: "",
            img: ""
          },
          {
            id: "111",
            name: "Emilia Ýr ",
            pids: [
              ""
            ],
            mid: "51",
            fid: "100",
            gender: "female",
            birth: "18-10-2015",
            death: "",
            img: ""
          },
          {
            id: "112",
            name: "Lúkas Kristófer ",
            pids: [
              ""
            ],
            mid: "51",
            fid: "100",
            gender: "male",
            birth: "18-04-2019",
            death: "",
            img: ""
          },
          {
            id: "113",
            name: "Freydís Helen Bjarnadóttir Kentish",
            pids: [
              ""
            ],
            mid: "55",
            fid: "",
            gender: "female",
            birth: "02-09-2008",
            death: "",
            img: ""
          },
          {
            id: "114",
            name: "Högni Hrafn Pálmason",
            pids: [
              ""
            ],
            mid: "102",
            fid: "56",
            gender: "male",
            birth: "04-10-2013",
            death: "",
            img: ""
          },
          {
            id: "115",
            name: "París Pálmadóttir",
            pids: [
              ""
            ],
            mid: "102",
            fid: "56",
            gender: "female",
            birth: "20-08-2018",
            death: "",
            img: ""
          },
          {
            id: "116",
            name: "Hólmfríður Katrín Pétursdóttir",
            pids: [
              ""
            ],
            mid: "103",
            fid: "57",
            gender: "female",
            birth: "27-04-2011",
            death: "",
            img: ""
          },
          {
            id: "117",
            name: "Huginn Pétursson",
            pids: [
              ""
            ],
            mid: "103",
            fid: "57",
            gender: "male",
            birth: "19-07-2014",
            death: "",
            img: ""
          },
          {
            id: "118",
            name: "Vera Pétursdóttir",
            pids: [
              ""
            ],
            mid: "103",
            fid: "57",
            gender: "female",
            birth: "07-10-2022",
            death: "",
            img: ""
          },
          {
            id: "119",
            name: "Ronja ",
            pids: [
              ""
            ],
            mid: "58",
            fid: "104",
            gender: "female",
            birth: "",
            death: "",
            img: ""
          },
          {
            id: "120",
            name: "Dagur Smári Ólafsson",
            pids: [
              ""
            ],
            mid: "65",
            fid: "",
            gender: "male",
            birth: "06-05-2009",
            death: "",
            img: ""
          },
          {
            id: "121",
            name: "Baldur Helgi Ólafsson",
            pids: [
              ""
            ],
            mid: "65",
            fid: "",
            gender: "male",
            birth: "06-05-2009",
            death: "",
            img: ""
          },
          {
            id: "122",
            name: "Hrafntinna Björk Ólafsdóttir",
            pids: [
              ""
            ],
            mid: "65",
            fid: "",
            gender: "female",
            birth: "10-08-2015",
            death: "",
            img: ""
          },
          {
            id: "123",
            name: "Vopni Týr Hrafnsson",
            pids: [
              ""
            ],
            mid: "105",
            fid: "66",
            gender: "male",
            birth: "04-10-2018",
            death: "",
            img: ""
          },
          {
            id: "124",
            name: "Darri Þór Hrafnsson",
            pids: [
              ""
            ],
            mid: "105",
            fid: "66",
            gender: "male",
            birth: "21-06-2021",
            death: "",
            img: ""
          },
          {
            id: "125",
            name: "Bjarmi Aronsson",
            pids: [
              ""
            ],
            mid: "106",
            fid: "71",
            gender: "male",
            birth: "03-04-2020",
            death: "",
            img: ""
          },
          {
            id: "126",
            name: "Heimir Aronsson",
            pids: [
              ""
            ],
            mid: "106",
            fid: "71",
            gender: "male",
            birth: "19-06-2022",
            death: "",
            img: ""
          },
          {
            id: "127",
            name: "Guðni Víðfjörð Hrafnsson",
            pids: [
              ""
            ],
            mid: "109",
            fid: "81",
            gender: "male",
            birth: "27-10-2022",
            death: "",
            img: ""
          },
          {
            id: "128",
            name: "Daníel Bragi Þorrason",
            pids: [
              ""
            ],
            mid: "110",
            fid: "85",
            gender: "male",
            birth: "08-08-2013",
            death: "",
            img: ""
          },
          {
            id: "129",
            name: "Ýmir Þorrason",
            pids: [
              ""
            ],
            mid: "110",
            fid: "85",
            gender: "male",
            birth: "21-08-2020",
            death: "",
            img: ""
          },
          {
            id: "130",
            name: "Högni Þorrason",
            pids: [
              ""
            ],
            mid: "110",
            fid: "85",
            gender: "male",
            birth: "06-04-2023",
            death: "",
            img: ""
          }
        ],
      });
    }
  }, [treeRef]);

  return <div className="Tree" ref={treeRef}></div>;
}

export default Tree;