import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Tree from './views/FamilyTree';
import Schedule from './views/Schedule';
import Map from './views/Map';
import Home from './views/Home';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <nav className="navbar">
          <ul>
          <li>
              <Link to="/">Rauðumýrargengið</Link>
            </li>
            <li>
              <Link to="/schedule">Dagskrá</Link>
            </li>
            <li>
              <Link to="/tree">Ættartré</Link>
            </li>
            <li>
              <Link to="/map">Kort</Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/tree" element={<Tree />} />
          <Route path="/map" element={<Map />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
