import React from 'react';

function Schedule() {
  return (
    <div>
      <h1>Dagskrá</h1>
      <div>
        <h1>FÖSTUDAGUR</h1>
        <div class="timing">
          <div class="time"><h3>20:00-</h3></div>
          <div class="event">
            <div class="event-heading">
              <h3>Lopapeysu Happy Hour á tjaldstæðinu í Hrafnagili.</h3>
            </div>
            <div class="event-text">
              <p>Aðeins að sýna sig og sjá aðra eftir að koma sér fyrir á tjaldstæðinu. Hvetjum að sjálfsögðu þá sem ekki ætla að gista þar, til að kíkja líka við.</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1>LAUGARDAGUR</h1>
        <div class="timing">
          <div class="time"><h3>11:30-</h3></div>
          <div class="event">
            <div class="event-heading">
              <h3>Ættarmót er sett! </h3>
            </div>
            <div class="event-text">
              <p>Grillum pylsur, förum í nokkra leiki og hristum hópinn aðeins saman. Hver kemur með á grillið fyrir sig og sína en nefndin græjar sósur og tilheyrandi.</p>
            </div>
          </div>
        </div>
        <div class="timing">
          <div class="time"><h3>13:00-</h3></div>
          <div class="event">
            <div class="event-heading">
              <h3>Ratleikur</h3>
            </div>
            <div class="event-text">
              <p>Flökkum um Akureyri og nágrenni, leysum þrautir og fræðumst aðeins um sögu fjölskyldunnar. Að sjálfsögðu stendur bara eitt lið uppi sem sigurvegari!</p>
            </div>
          </div>
        </div>
        <div class="timing">
          <div class="time"><h3>16:00-</h3></div>
          <div class="event">
            <div class="event-heading">
              <h3>Kirkjugarður</h3>
            </div>
            <div class="event-text">
              <p>Förum í Kirkjugarðinn á Akureyri og minnumst þeirra sem við eigum þar.</p>
            </div>
          </div>
        </div>
        <div class="timing">
          <div class="time"><h3>19:30</h3></div>
          <div class="event">
            <div class="event-heading">
              <h3>Matur & Kvöldvaka</h3>
            </div>
            <div class="event-text">
              <p>Grillum, skálum einu sinni eða tvisvar og eigum skemmtilega kvöldstund saman í Laugarborg. 
                Hver kemur með á grillið fyrir sig en nefndin skaffar meðlæti. Hver sér um drykki fyrir sig. 
                Þeir sem hafa áhuga á að vera með skemmtiatriði um kvöldið mega endilega láta nefndina vita sem fyrst.</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1>ÞUNNUDAGUR</h1>
        <div class="timing">
          <div class="time"><h3>20:00-</h3></div>
          <div class="event">
            <div class="event-heading">
              <h3>Kveðjustund</h3>
            </div>
            <div class="event-text">
              <p>Frágangur, pökkun og heimferð fyrir þá sem búa ekki í höfuðstað norðursins. 
                Við vorum svo að búa til skráningaform þar sem við biðjum ykkur um að skrá afkomanda, börn & maka. 
                Endilega skráið ykkur í gegnum það sem fyrst! https://forms.gle/TGUoV112DwfzWCYq6</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Schedule;