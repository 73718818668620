import React from 'react';

function Home() {
  return (
    <div>
      <h1>Rauðumýrargengið</h1>
      <p>Þessi síða er hugsuð til að halda utan um ættarmót Rauðumyrargengisins, en næsta ættarmót er 7.-9. júlí 2023.</p><p> Rauðumýrargengið er afkomendur Afkomendur Helga Haraldssonar og Marselínu Kjartansdóttur sem byggðu Rauðumýri 15 á Akureyri</p>
    </div>
  );
}

export default Home;